// import logo from './logo.svg';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CookieConsent from "./components/CookieConsent/CookieConsent"; // Komponen Cookie Consent
import { Footer } from "./components/Elements/Foot/Footer";
import { Navbar } from "./components/Elements/Nav/Navbar";
import About from "./pages/About/About";
import Board from "./pages/About/BoardOfDirector/Boards";
import MilestoneAward from "./pages/About/MilestoneAwards/MilestoneAward";
import AddNews from "./pages/Admin/AddNews";
import EditNews from "./pages/Admin/EditNews";
import NewsAdmin from "./pages/Admin/NewsAdmin";
import Career from "./pages/Career/Career";
import { Contact } from "./pages/Contact/Contact";
import Esg from "./pages/ESG/Esg";
import Industries from "./pages/Industries/Industries";
import LandingPage from "./pages/LandingPages/LandingPage";
import { DetailNews } from "./pages/News/DetailNews";
import { News } from "./pages/News/News";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'; // Halaman Privacy Policy
import { Products } from "./pages/Products/Products";
import ProgramCSR from "./pages/ProgramCSR/ProgramCSR";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/product" element={<Products />} />
          <Route path="/board" element={<Board />} />
          <Route path="/awards" element={<MilestoneAward />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/news" element={<News />} />
          <Route path="/detail/:id" element={<DetailNews />} />
          <Route path="/about" element={<About />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/career" element={<Career />} />
          <Route path="/programcsr" element={<ProgramCSR />} />
          <Route path="/esg" element={<Esg />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Route Privacy Policy */}
          {/* Admin  */}
          <Route path="/addnews" element={<AddNews />} />
          <Route path="/editnews/:id" element={<EditNews />} />
          <Route path="/newsadmin" element={<NewsAdmin/>} />
        </Routes>
      </Router>
      <CookieConsent /> {/* Tambahkan Cookie Consent di sini */}
      <Footer />
    </>
  );
}

export default App;
