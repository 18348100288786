// INDUSTRIIIESS
import mapdpp from "./Industries/Maps_DPP.png";
import fabrication1 from "./Industries/fabrication/fabrication1.JPG";
import fabrication2 from "./Industries/fabrication/fabrication2.JPG";
import fabrication3 from "./Industries/fabrication/fabrication3.JPG";
import fabrication4 from "./Industries/fabrication/fabrication4.JPG";
import manpower6 from "./Industries/manpowersite/crop_manpo.jpeg";
import manpower1 from "./Industries/manpowersite/manpo1.jpeg";
import manpower2 from "./Industries/manpowersite/manpo2.jpeg";
import manpower3 from "./Industries/manpowersite/manpo3.jpeg";
import manpower4 from "./Industries/manpowersite/manpo4.jpeg";
import manpower5 from "./Industries/manpowersite/manpo5.jpeg";
import manufacturing1 from "./Industries/manufacturing/manu1.JPG";
import manufacturing2 from "./Industries/manufacturing/manu2.JPG";
import manufacturing3 from "./Industries/manufacturing/manu3.JPG";
import manufacturing4 from "./Industries/manufacturing/manu4.JPG";
import weldertest1 from "./Industries/welder/test1.jpeg";
import weldertest2 from "./Industries/welder/test2.jpg";
import weldertest3 from "./Industries/welder/test3.jpg";
import weldertest4 from "./Industries/welder/test4.jpg";
import weldertest5 from "./Industries/welder/test5.jpg";

//OUR CLIENT
import patria from "./Brand/logopatria.png";
import pli from "./Brand/pli.png";
import pmi from "./Brand/pmi.png";
import pml from "./Brand/pml.png";
import pmp from "./Brand/pmp.png";
import sigma from "./Brand/sigma.png";
import triatra from "./Brand/triatra.png";
import ut from "./Brand/ut.png";

// PRODUCT
import attachment from "./Product/Attachimage.jpg";
import bulktank from "./Product/bulktank.png";
import fueltruck from "./Product/fuel-truck.png";
import lowbedtrailer from "./Product/lowbedtrailer.png";
import lubetruck from "./Product/lubetruck.png";
import manhauler from "./Product/manhauler.png";
import mediumvessel from "./Product/mediumvessel.jpg";
import mixer from "./Product/mixer.png";
import onroadtrailer from "./Product/onroad-trailer.png";
import semitrailertipper from "./Product/semi-trailler-tipper.png";
import semitrailer from "./Product/semitrailer.png";
import stemmingtruck from "./Product/stemmingtruck.jpg";
import tippervessel from "./Product/tippervessel.jpg";
import towerlamp from "./Product/towerlamp.png";
import xprovesselbig from "./Product/xpro-vesselbig.png";
import xprovessel from "./Product/xprovessel.png";

// BACKGROUND OVERLAY
import background10 from "./BackgroundOverlay/background10.JPG";
import background12 from "./BackgroundOverlay/background12.JPG";
import background13 from "./BackgroundOverlay/background13.JPG";
import background15 from "./BackgroundOverlay/background15.JPG";
import background16 from "./BackgroundOverlay/background16.jpeg";
import background17 from "./BackgroundOverlay/background18.JPG";
import background2 from "./BackgroundOverlay/background2.JPG";
import background3 from "./BackgroundOverlay/background3.JPG";
import background7 from "./BackgroundOverlay/background7.JPG";
import background8 from "./BackgroundOverlay/background8.JPG";
import background9 from "./BackgroundOverlay/background9.JPG";
import backgroundnews from "./BackgroundOverlay/bgnews.JPG";
import contact from "./BackgroundOverlay/contact.JPG";
import esg from "./BackgroundOverlay/esg.JPG";

// ABOUT
import about from "./About/about.JPG";
import jamkerja from "./About/jamkerja.jpg";
import milestone from "./About/milestone.jpg";
import companystructure from "./About/structure.jpg";

//BOD
import amalik from "./About/presdir/Amalik.jpeg";
import ari from "./About/presdir/Ari Sasongko.jpeg";
import leo from "./About/presdir/Leomahesa.jpeg";
import haryono from "./About/presdir/YFX Haryono.jpeg";

//LANDING
import fabrication from "./LandingPage/fabrication.webp";
import bghome from "./LandingPage/homedpp2.png";
import landing1 from "./LandingPage/landang10.png";
import manufacturing from "./LandingPage/manufacturing.webp";
import imgrounded from "./LandingPage/ronded.png";
import weldercertification from "./LandingPage/weldercertification.webp";
import wistle from "./LandingPage/whistle.png";
import logodpp from "./dpplogo.png";

// CSR

import care from "./ProgramCSR/care1.png";
import edu from "./ProgramCSR/education.png";
import green from "./ProgramCSR/green1.png";
import health from "./ProgramCSR/health1.png";


import pakamalik from "./About/presdir/pakamalik.JPG";
import pakarie from "./About/presdir/pakarie.JPG";
import pakhar from "./About/presdir/pakhar.JPG";
import broken from "./broken-image.png";

export {
  about, amalik, ari, attachment, background10, background12, background13, background15, background16, background17, background2,
  background3,
  background7, background8, background9, backgroundnews, bghome, broken, bulktank, care, companystructure, contact, edu, esg, fabrication, fabrication1,
  fabrication2,
  fabrication3,
  fabrication4, fueltruck, green, haryono, health, imgrounded, jamkerja,
  landing1, leo, logodpp, lowbedtrailer,
  lubetruck,
  manhauler, manpower1,
  manpower2,
  manpower3,
  manpower4,
  manpower5, manpower6, manufacturing, manufacturing1,
  manufacturing2,
  manufacturing3,
  manufacturing4,
  mapdpp, mediumvessel, milestone, mixer,
  onroadtrailer, pakamalik, pakarie, pakhar, patria,
  pli,
  pmi,
  pml,
  pmp, semitrailer,
  semitrailertipper, sigma, stemmingtruck,
  tippervessel,
  towerlamp, triatra, ut, weldercertification, weldertest1,
  weldertest2,
  weldertest3,
  weldertest4,
  weldertest5, wistle, xprovessel,
  xprovesselbig
};

