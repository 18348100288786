import React, { useEffect } from "react";
import { background7 } from "../../assets";
import BackgroundOverlay from "../../components/Fragments/BackgroundOverlay"; // BackgroundOverlay seperti di halaman ESG

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Memastikan halaman dimulai dari atas
  }, []);

  return (
    <>
      {/* Menampilkan BackgroundOverlay seperti di halaman ESG */}
      <BackgroundOverlay
        content="Kebijakan Privasi"
        gambar={background7}
      />

      <div className="relative pb-20 -mt-20 bg-slate-50 overflow-x-hidden">
        {/* Card untuk isi kebijakan privasi */}
        <div className="container mx-auto p-4 lg:p-10 max-w-4xl">
          <div className="bg-white shadow-lg rounded-lg p-6 lg:p-10">
            <h1 className="text-lg font-bold text-center mb-6 lg:mb-10">KEBIJAKAN PERLINDUNGAN DATA PERUSAHAAN DAN DATA PRIBADI PEKERJA</h1>
            <h2 className="text-lg font-bold text-center mb-4 lg:mb-5">PT DIAN PANDU PRATAMA</h2>

            <p className="text-sm mt-4 text-justify">
              <strong>PT DIAN PANDU PRATAMA</strong> (“selanjutnya disebut “<strong>Perusahaan</strong>”) bertanggung jawab dalam melindungi Data Pribadi Pekerja PT Dian Pandu Pratama (selanjutnya disebut “<strong>Pekerja</strong>”) dan tunduk kepada Hukum yang berlaku. Perusahaan memiliki komitmen untuk
              mengelola, melindungi dan memproses Data Pribadi Pekerja sesuai dengan KEBIJAKAN ini dan
              hukum yang berlaku terhadap seluruh Data Pribadi Pekerja dan Data Pribadi pihak ketiga yang
              diperoleh dan diterima Perusahaan dari waktu ke waktu. Begitu juga Pekerja bertanggung jawab
              untuk mengelola, melindungi dan memproses Data Perusahaan sesuai dengan KEBIJAKAN ini dan
              hukum yang berlaku yang diperoleh Pekerja dari waktu ke waktu.
            </p>
            <p className="text-sm mt-4 text-justify">
              Dengan mengklik button “<strong>Dengan ini Saya Setuju</strong>” melalui Website Company Profile ini, maka Pekerja telah memberikan persetujuan atas isi dari KEBIJAKAN ini.
            </p>

            {/* Section I */}
            <h2 className="text-sm font-bold mt-10 ">1. I. PERLINDUNGAN DATA PERUSAHAAN</h2>
            <br></br>
            <ol style={{ listStyleType: "upper-alpha" }} className="ml-6 text-sm text-justify">
              <li>Perusahaan bermaksud untuk mengikat, menjamin dan memastikan Pekerja untuk menjaga,
                memelihara, dan tidak menggungkapkan informasi, data, dokumen yang dimiliki, diketahui,
                dan disimpan milik Perusahaan, termasuk dan tidak terbatas atas informasi, data dan
                dokumen milik Pekerja yang timbul dan diketahui karena status kerja Pekerja sebagai Pekerja
                (tenaga kerja) di Perusahaan (selanjutnya disebut sebagai "<strong>Pekerjaan</strong>”).</li>
                <br></br>
              <li>Perusahaan dan Pekerja memahami bahwa untuk keperluan pelaksanaan Pekerjaan,
                Perusahaan akan dan harus mengungkapkan beberapa informasi tertentu kepada Pekerja
                dengan tunduk pada persyaratan dan ketentuan dalam KEBIJAKAN ini.</li>
                <br></br>
              <li>Dalam KEBIJAKAN ini, "<strong>Informasi Rahasia</strong>" berarti setiap informasi yang tidak bersifat umum
                yang dimiliki oleh Perusahaan yang diungkapkan atau diberikan kepada Pekerja, tanpa
                memperhatikan bentuk dan cara pengungkapan, termasuk namun tidak terbatas pada:</li>
            </ol>
            <br></br>
            <ol className="list-decimal text-sm ml-10 pl-4 text-justify">
              <li>
                setiap informasi yang terkait dengan rahasia dagang Perusahaan (termasuk seluruh informasi yang didefinisikan sebagai “rahasia dagang” berdasarkan peraturan perundangan-undangan yang berlaku);
              </li>
              <br></br>
              <li>
                setiap informasi yang didapat oleh Pekerja dalam melaksanakan pekerjaannya di
                tempat usaha Perusahaan, termasuk tetapi tidak terbatas pada informasi mengenai
                produk, spesifikasi produk, data, username dan password, informasi kode, formula,
                komposisi, desain, sketsa, foto, grafik, gambar, contoh produk, karya cipta,
                penemuan, ide, pengetahuan, hasil riset dan pengembangan di masa lalu, masa
                sekarang dan masa depan dan proses serta metode yang terencana, daftar dan file
                klien atau pelanggan, persyaratan klien atau pelanggan yang ada atau yang akan ada,
                daftar dan file vendor dan pemasok, daftar harga, riset pasar, rencana bisnis, kondisi
                operasional dan peluang bisnis; </li>
                <br></br>
              <li>
                setiap informasi yang berhubungan dengan kegiatan usaha Perusahaan, aset,
                kewajiban, laporan keuangan terakhir dan dimasa lampau, proyeksi keuangan dan
                anggaran, penjualan dimasa lampau, saat ini dan proyeksi penjualan di masa depan,
                anggaran belanja modal, prakiraan, rencana strategis, rencana pemasaran dan
                periklanan, publikasi, perjanjian, nama dan latar belakang Pekerja kunci dan materi
                serta teknik pelatihan Pekerja, dan nama, informasi kontak dan setiap informasi
                lainnya dari setiap orang;
              </li>
              <br></br>
              <li>
                setiap informasi rahasia milik pihak ketiga yang dimiliki Perusahaan;
              </li>
              <br></br>
              <li>
                setiap catatan, analisa, kompilasi, studi, ringkasan, negosiasi atau diskusi yang sedang berlangsung, penafsiran atau materi lainnya yang dibuat oleh Pekerja atau Perwakilannya sejauh mana memuat atau dibuat berdasarkan atau merujuk pada salah satu informasi di atas.
              </li>
            </ol>

            {/* Kalimat tambahan sebelum melanjutkan ke poin berikutnya */}
            <p className="text-sm mt-6 text-justify">
              Istilah "Informasi Rahasia" tidak termasuk setiap bagian dari informasi tersebut di atas:
            </p>
            <br></br>

            {/* Melanjutkan list dari 6 hingga 10 */}
            <ol start="6" className="list-decimal text-sm ml-10 pl-4 text-justify">
              <li>Yang telah diketahui umum dan bukan sebagai akibat dari pengungkapan yang dilakukan oleh Perusahaan atau Perwakilannya; atau bukan dikarenakan kesalahan/kelalaian dari Pekerja; atau</li>
              <br></br>
              <li>Dikembangkan sendiri oleh Pekerja tanpa merujuk pada suatu Informasi Rahasia; atau</li>
              <br></br>
              <li>Telah memperoleh persetujuan untuk diungkapkan oleh Perusahaan; atau</li>
              <br></br>
              <li>Telah dipublikasikan sesuai dengan persyaratan sah dari pengadilan atau lembaga pemerintah atau sebagaimana dipersyaratkan oleh hukum;</li>
              <br></br>
              <li>Yang telah diketahui atau diberitahukan kepada Pekerja tanpa ikatan kerahasiaan sebelum informasi tersebut diberikan kepada Pekerja, tetapi hanya bila sumber dari informasi tersebut tidak terikat kewajiban kerahasiaan.</li>
            </ol>

            <p className="text-sm mt-4">
              D. PEMBATASAN PENGUNGKAPAN DAN LARANGAN MENGUNGKAPKAN
            </p>
            <br></br>

            <ol className="list-decimal text-sm ml-10 pl-4 text-justify">
              <li>
                Pekerja setuju untuk (i) menjaga kerahasiaan Informasi Rahasia dan (ii) tanpa
                membatasi ketentuan sebelumnya, tidak akan mengungkapkan, membocorkan,
                menyalin, menggandakan, mereproduksi, mendistribusikan, dan/atau menyebarkan
                Informasi Rahasia kepada pihak manapun kecuali dengan persetujuan tertulis
                secara khusus dari Atasan Langsung Pekerja. Pekerja memahami bahwa ia hanya
                diperkenankan mengungkapkan Informasi Rahasia kepada pihak lain yang disetujui
                oleh Atasan Langsung Pekerja dari Perusahaan yang telah diinformasikan mengenai
                sifat kerahasiaan Informasi Rahasia dan kewajiban-kewajiban dalam KEBIJAKAN ini
                oleh Pekerja. Pekerja tidak diperkenankan menggunakan Informasi Rahasia untuk
                tujuan lain selain dari untuk melakukan Pekerjaan demi menunjang kegiatan usaha
                Perusahaan.</li>
                <br></br>
              <li>
                Pekerja akan mengambil semua langkah yang diperlukan (termasuk semua tindakan
                yang akan diambil Pekerja untuk melindungi Informasi Rahasia dan untuk
                memastikan Pekerja mematuhi seluruh ketentuan dalam KEBIJAKAN ini dan Pekerja
                bertanggung jawab atas pelanggaran tersebut. Oleh karena itu, Pekerja wajib
                mencegah penyalahgunaan atau pengungkapan Informasi Rahasia diluar yang
                diperkenankan oleh KEBIJAKAN ini. Apabila terjadi penyalahgunaan atau
                pengungkapan yang tidak diperkenankan, Pekerja akan segera memberitahukan
                Perusahaan dan mengambil langkah-langkah yang diperlukan sesuai dengan
                ketentuan hukum yang berlaku (termasuk mengadakan penyitaan atau meminta
                putusan sela) atas biaya Pekerja untuk mengembalikan Informasi Rahasia dan untuk
                mencegah penyebaran atau penyalahgunaan lebih lanjut. Apabila Pekerja lalai dalam
                mengambil langkah-langkah tersebut diatas dalam waktu dan cara yang memadai,
                Perusahaan dapat mengambil sendiri langkah-langkah tersebut atau atas nama
                Pekerja dan atas biaya Pekerja.</li><br></br>
              <li>
                Ketentuan pembatasan mengenai pengungkapan Informasi Rahasia tidak berlaku
                apabila dan sepanjang Pekerja dapat menunjukkan bahwa:
              </li><br></br>

            </ol>
            <ol className="list-decimal text-sm ml-12 pl-8 text-justify">
              <li>
                (i) dipersyaratkan oleh hukum yang berlaku atau pengawasan, pengaturan,
                pengadilan, atau badan pemerintah, perbankan, pajak, yang memiliki
                jurisdiksi;</li><br></br>
              <li>
                (ii) pengungkapan Informasi Rahasia tersebut terkait dengan setiap proses
                hukum;
              </li><br></br>
              <li>
                (iii) dalam hal pengungkapan atau penggunaan, Informasi Rahasia yang
                bersangkutan tersebut secara hukum merupakan miliknya (sebagaimana
                dibuktikan dengan catatan tertulis) sebelum Informasi Rahasia tersebut
                diperoleh atau diterima dan tidak tunduk pada setiap kewajiban kerahasiaan
                sebelum Informasi Rahasia diterima atau dipegang;
              </li><br></br>
              <li> (iv) dalam hal pengungkapan atau penggunaan, Informasi Rahasia tersebut
                telah menjadi domain publik selain dari kesalahannya atau kesalahan dari
                setiap orang yang kepadanya Informasi Rahasia tersebut telah diungkapkan.
              </li>

            </ol>

            {/* Kalimat tambahan sebelum melanjutkan ke poin berikutnya */}
            <p className="text-sm mt-6 text-justify">
              Ketentuan dalam poin (3) ini dapat dilaksanakan dengan memberitahukan terlebih dahulu kepada
              Perusahaan;</p>
              <br></br>

            <ol start="4" className="list-decimal text-sm ml-10 pl-4 text-justify">
              <li>Pembatasan mengenai pengungkapan Informasi Rahasia ini akan terus berlaku
                setelah penyelesaian KEBIJAKAN ini dan akan terus berlanjut tanpa batas waktu,
                kecuali disepakati sebaliknya secara tertulis oleh Para Pihak.
              </li><br></br>
              <li> Pekerja berkewajiban untuk memberitahukan Perusahaan dalam waktu 5 (lima) hari
                kalendar atas penemuan pengungkapan Informasi Rahasia ke pihak manapun yang
                terjadi bukan karena kelalaian Pekerja, dan akan berupaya secara optimal untuk
                memperoleh kembali Informasi Rahasia tersebut atau mengurangi kemungkinan
                meluasnya pengungkapan Informasi Rahasia tersebut untuk mencegah penggunaan
                yang tidak sah atas Informasi Rahasia tersebut.</li><br></br>
              <li>Apabila pengungkapan Informasi Rahasia sebagaimana tersebut di atas terjadi
                karena kelalaian Pekerja, maka Pekerja wajib memberikan ganti rugi yang setara
                kepada Perusahaan atas kerugian yang telah dan akan diderita oleh Perusahaan
                akibat pengungkapan Informasi Rahasia tersebut.</li>
            </ol>


            <p className="text-sm mt-6 pl-4">
              E. LARANGAN PENGUNGKAPAN TRANSAKSI
            </p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Pekerja dilarang mengungkapkan, membocorkan, menyalin, menggandakan, mereproduksi,
              mendistribusikan, dan/atau menyebarkan kepada pihak manapun, tanpa mendapatkan
              persetujuan tertulis terlebih dahulu dari Atasan Langsung Pekerja, kecuali hal tersebut
              dilakukan dengan etikad baik untuk tujuan pelaksanaan Pekerjaan dan sesuai dengan
              peraturan perundang-undangan yang berlaku.
            </p>

            <p className="text-sm mt-6 pl-4">
              F. KEWAJIBAN HUKUM UNTUK MENGUNGKAPKAN INFORMASI RAHASIA</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Apabila Pekerja diwajibkan secara hukum (termasuk oleh peraturan atau aturan yang
              dikeluarkan oleh otoritas pasar modal) untuk mengungkapkan Informasi Rahasia yang
              dilarang atau dibatasi oleh KEBIJAKAN ini, maka Pekerja harus segera memberikan
              pemberitahuan tertulis mengenai kewajiban tersebut sehingga ia dapat meminta putusan
              perlindungan atau pembebasan lainnya atau mengesampingkan kewajiban untuk tidak
              melakukan pengungkapan yang diatur dalam KEBIJAKAN ini. Tunduk pada ketentuan
              sebelumnya, Pekerja hanya dapat melakukan pengungkapan tersebut apabila terdapat
              kewajiban pada pengungkapan tersebut, dalam pendapat hukum penasihat hukum yang
              dapat diterima oleh Perusahaan, diwajibkan oleh hukum atau diharuskan untuk menghindari
              penuntutan atau pengenaan sanksi atau denda; dengan catatan, Pekerja harus
              menggunakan segala cara untuk mendapatkan jaminan kepastian bahwa kerahasiaan dari
              Informasi Rahasia tersebut akan tetap terjaga.</p>


            <p className="text-sm mt-6 pl-4">
              G. KEWAJIBAN HUKUM UNTUK MENGUNGKAPKAN INFORMASI RAHASIA</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Apabila Pekerja diwajibkan secara hukum (termasuk oleh peraturan atau aturan yang
              dikeluarkan oleh otoritas pasar modal) untuk mengungkapkan Informasi Rahasia yang
              dilarang atau dibatasi oleh KEBIJAKAN ini, maka Pekerja harus segera memberikan
              pemberitahuan tertulis mengenai kewajiban tersebut sehingga ia dapat meminta putusan
              perlindungan atau pembebasan lainnya atau mengesampingkan kewajiban untuk tidak
              melakukan pengungkapan yang diatur dalam KEBIJAKAN ini. Tunduk pada ketentuan
              sebelumnya, Pekerja hanya dapat melakukan pengungkapan tersebut apabila terdapat
              kewajiban pada pengungkapan tersebut, dalam pendapat hukum penasihat hukum yang
              dapat diterima oleh Perusahaan, diwajibkan oleh hukum atau diharuskan untuk menghindari
              penuntutan atau pengenaan sanksi atau denda; dengan catatan, Pekerja harus
              menggunakan segala cara untuk mendapatkan jaminan kepastian bahwa kerahasiaan dari
              Informasi Rahasia tersebut akan tetap terjaga.</p>

            <p className="text-sm mt-6 pl-4">
              H. PENGEMBALIAN INFORMASI RAHASIA</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Apabila Pekerja memutuskan pengunduran diri dan/atau di mutasi dari PT Dian Pandu Pratama, maka Pekerja memiliki waktu sepuluh (10) hari untuk (i) mengembalikan
              kepada Perusahaan seluruh dokumen dan materi lainnya yang merupakan Informasi
              Rahasia, selain Catatan, yang berada dalam kendali Pekerja; (ii) menghancurkan seluruh
              salinan dokumen atau materi lainnya yang merupakan Informasi Rahasia, kecuali yang
              disimpan didalam media elektronik atau media lainnya dan dapat diambil dalam bentuk yang
              bisa dilihat darimana Informasi Rahasia tersebut disimpan dalam sebuah sistem
              manajemen dokumen atau email yang dimiliki atau dikendalikan oleh Pekerja dan (iii)
              menyerahkan surat pernyataan yang ditandatangani oleh pejabat berwenang dari Pekerja
              yang menyatakan bahwa seluruh salinan dokumen dan materi lain yang merupakan
              Informasi Rahasia, selain Catatan, yang disimpan didalam media elektronik atau media
              lainnya dan dapat diambil dalam bentuk yang bisa dilihat yang dimiliki atau dikendalikan oleh
              Pekerjadan yang belum dihapus tidak akan diambil, diakses, atau dipergunakan dengan cara
              apapun juga oleh Pekerja tanpa persetujuan tertulis dari Perusahaan.
            </p>

            <p className="text-sm mt-6 pl-4">
              I. PERLINDUNGAN DATA DAN PEMBATASAN PRIVASI</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Perusahaan dan Pekerja dengan ini tunduk pada setiap ketentuan terkait perlindungan data/
              privasi yang berlaku di Indonesia, dan akan menjalankan dan menjaga seluruh prosedur
              keamanan teknis dan organisasi yang tersedia secara wajar (sesuai dengan perkembangan
              teknologi saat itu) dan sebagaimana layak untuk melindungi Informasi Rahasia dari
              penggunaan data tanpa izin atau pemrosesan dan penyebaran yang melanggar hukum.
              Dalam hal suatu Informasi Rahasia berada di negara yang melarang transfer informasi ke
              negara atau lokasi lain, Pekerja akan menjaga informasi tersebut dalam negara tersebut saja
              dan tidak akan memindahkannya ke negara lain sehingga melanggar pembatasan tersebut.
              Pekerja akan menjamin dan mengganti kerugian Perusahaan dari seluruh kerugian dan biayabiaya (termasuk biaya hukum) yang timbul sebagai akibat dari pelanggaran suatu peraturan
              perundangan mengenai data protection dan/atau hukum soal privasi.
            </p>

            <p className="text-sm mt-6 pl-4">
              J.  GANTI RUGI</p>

            <p className="text-sm mt-4 pl-8 text-justify">
              Pekerja menjamin dan akan membebaskan Perusahaan dari kerusakan, kerugian, biaya atau
              tanggung jawab (termasuk biaya hukum dan biaya untuk pelaksanaan jaminan ini) yang
              timbul dari atau sebagai akibat dari pelanggaran ketentuan Perjanjian ini oleh Pekerja atau
              dari penyalahgunaan atau pengungkapan yang tidak diperkenankan dari Informasi Rahasia
              oleh Pekerja. Hak dan ganti rugi Para Pihak berdasarkan Perjanjian ini bersifat kumulatif.
            </p>

            <h2 className="text-sm font-bold mt-10">1. II.PERLINDUNGAN DATA PRIBADI PEKERJA</h2>
            <br></br>
            <ol style={{ listStyleType: "upper-alpha" }} className="ml-6 text-sm text-justify" >
              <li>Pekerja memberikan persetujuan dan memberikan konfirmasi kepada Perusahaan untuk
                memperoleh, mengumpulkan, menyimpan, menguasai, menggunakan, mengolah,
                menganalisa, memperbaiki, melakukan pembaruan, menampilkan, mengumumkan,
                mentransfer, mengungkapkan dan melindungi Data Pribadi yang diberikan oleh Pekerja dan
                diperoleh oleh Perusahaan (“Memproses Data Pribadi” atau melakukan “Pemrosesan Data
                Pribadi”) sesuai dengan tujuan pemrosesan Data Pribadi, dan sesuai dengan peraturan
                perundang-undangan yang berlaku. Untuk menghindari keragu-raguan, Data Pribadi adalah
                data tentang orang perseorangan yang teridentifikasi atau dapat diidentifikasi secara
                tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun tidak
                langsung melalui sistem elektronik atau nonelektronik.
              </li><br></br>
              <li>Perusahaan memastikan bahwa semua Data Pribadi Pekerja yang dikumpulkan dan
                disampaikan Pekerja akan disimpan dengan aman.</li><br></br>
              <li> Pekerja berkewajiban untuk memastikan bahwa Data Pribadi yang diberikan berikut
                perubahanperubahan yang dilakukan di masa mendatang adalah akurat dan benar.
                Perusahaan tidak bertanggung jawab atas segala akibat yang dapat terjadi sehubungan
                dengan pemberian Data Pribadi berikut perubahan-perubahan yang dilakukan tersebut.
                Pekerja bertanggungjawab dan bersedia menerima konsekuensi atas segala bentuk
                pelanggaran atas syarat dan ketentuan di atas dan/atau tindakan pelanggaran,
                penyalahgunaan, baik kesengajaan maupun kelalaian sehubungan dengan Data Pribadi yang
                diberikan kepada Perusahaan, termasuk menimbulkan kerugian bagi Perseroan.</li><br></br>
              <li>Apabila Pekerja memberikan Data Pribadi yang terkait dengan individu lain kepada
                Perusahaan sesuai kebutuhan Perusahaan, Pekerja secara tegas menyatakan dan menjamin
                bahwa Pekerja telah memperoleh persetujuan yang sah dari individu tersebut dan dengan
                demikian Pekerja mengizinkan Perusahaan untuk melakukan Pemrosesan Data Pribadi
                mereka. Perusahaan berhak untuk meminta bukti persetujuan mereka dari Pekerja kapan
                pun diperlukan. Dengan memberikan pernyataan dan jaminan ini, Pekerja menyetujui untuk
                melindungi Perusahaan dari segala tanggung jawab hukum yang timbul sehubungan dengan
                hal tersebut.</li><br></br>
              <li>Data Pribadi Pekerja yang diproses oleh Perusahaan, meliputi:</li>
            </ol>
            <br></br>
            <ol className="list-decimal text-sm ml-10 pl-4 text-justify">
              <li>
                Identitas Pekerja yang terdiri dari nama, alamat tempat tinggal, setiap data yang
                tersedia pada Kartu Tanda Penduduk, kata sandi, nomor telepon, alamat surat
                elektronik, nomor identitas karyawan, jabatan, identitas terkait perpajakan, dan/atau
                informasi lain yang dapat menunjukan identitas diri Pekerja;</li><br></br>
              <li>
                Identitas keluarga Pekerja yang terdiri dari nama dan Kartu Keluarga.
              </li><br></br>
              <li>
                Data keuangan yang terdiri dari antara lain nomor rekening Anda dan/atau data
                keuangan lain yang relevan sebagaimana diperlukan Perseroan dalam hubungan
                kerja dengan Anda sebagai karyawan Perseroan;</li><br></br>
              <li>
                Data teknis yang terdiri dari (i) IP Address yang digunakan oleh Anda saat mengakses
                Layanan Kami, khususnya yang tersedia untuk karyawan Perseroan, (ii) history
                penggunaan Layanan Kami, (iii) durasi yang digunakan oleh masing-masing Anda
                dalam mengakses Layanan Kami, (iv) kehadiran Anda sebagai karyawan Perseroan,
                (v) data terkait penggunaan perangkat yang digunakan untuk mengakses Layanan
                Kami, dan/atau data teknis lainnya terkait penggunaan Layanan Kami oleh Anda
                sebagai karyawan Perseroan;</li><br></br>
              <li>
                Informasi mengenai lokasi Anda sebagai karyawan Perseroan yang didapatkan saat
                Anda menuliskan lokasi Anda ataupun yang dihasilkan oleh geolocation yang harus
                diaktifkan saat Anda mengakses Layanan Kami khusus untuk karyawan Perseroan;</li><br></br>
              <li> Informasi yang bersifat khusus seperti rekam medis, informasi kesehatan, data anak,
                data pasangan, informasi mengenai gaji, dan/atau data lainnya yang diperlukan
                Perseroan dalam hubungan kerja dengan Anda sebagai karyawan Perseroan; dan
              </li><br></br>
              <li>Perseroan dapat mengkombinasikan Data Pribadi yang didapatkan untuk keperluan
                statistik, demografi, maupun dalam pengembangan Layanan Kami (selanjutnya
                disebut sebagai “<strong>Data yang Dikombinasi</strong>”). Data yang Dikombinasi pada dasarnya
                tidak akan mengungkapkan Data Pribadi. Perseroan akan tetap memastikan bahwa
                Data yang Dikombinasi tidak akan dapat digunakan untuk mengidentifikasi
                seseorang, atau tidak dapat dikaitkan atau dihubungkan dengan siapapun</li>
            </ol>
            <br></br>
            <ol style={{ listStyleType: "upper-alpha" }} className="ml-6 text-sm" start="6">
              <li>Perusahaan memproses Data Pribadi Pekerja untuk tujuan-tujuan, sebagai berikut:
              </li>
            </ol>
            <br></br>
            <ol className="list-decimal text-sm ml-10 pl-4 text-justify">
              <li>
                Menanggapi pertanyaan, komentar atau keluhan;</li><br></br>
              <li>
                Berkomunikasi dengan Pekerja melalui berbagai media seperti notifikasi dalam
                Layanan Kami, surat elektronik, telepon, layanan pesan singkat (SMS), layanan
                percakapan (chatting) pesan singkat dalam aplikasi (in-app messaging/ push
                notifications), maupun media sosial;
              </li><br></br>
              <li>
                Melaksanakan hubungan hukum antara Pekerja dengan Perusahaan;</li><br></br>
              <li>
                Melakukan proses administrasi internal dan/atau kepatuhan terhadap hukum yang
                berlaku di Indonesia, seperti namun tidak terbatas pada pelaksanaan audit,
                perpajakan, pencegahan tindak pidana pencucian uang; dan/atau</li><br></br>
              <li>
                Mengungkap Data Pribadi Anda untuk tujuan bisnis dan operasional kepada pihak
                ketiga, termasuk namun tidak terbatas pada:
              </li><br></br>
            </ol>
            <br></br>
            <ol className="list-decimal text-sm ml-16 pl-8 text-justify">
              <li>
                (i) Perusahaan penyedia jasa pemrosesan transaksi pembayaran yang
                meliputi penyelenggara payment gateway, transfer dana, dompet elektronik,
                uang elektronik dan penyelenggara jasa sistem pembayaran lainnya terkait
                transaksi yang dilakukan dalam Situs;</li><br></br>
              <li>
                (ii) Perusahaan penyedia layanan lainnya maupun mitra Perusahaan yang
                terkait dengan produk dan/atau layanan yang Perusahaan sediakan
                (termasuk namun tidak terbatas pada perusahaan asuransi dan/atau
                perusahaan penyedia jasa kesehatan untuk layanan bagi karyawan
                Perusahaan);
              </li><br></br>
              <li>
                (iii) Penasihat profesional dan auditor eksternal, termasuk penasihat hukum,
                penasihat keuangan dan konsultan lainnya;</li><br></br>
              <li>
                (iv) Otoritas pemerintah, baik di dalam maupun di luar yurisdiksi negara
                Republik Indonesia sesuai Peraturan Perundang-undangan yang Berlaku;
                dan/atau</li><br></br>
              <li>
                (v) Pihak-pihak lainnya sesuai dengan peraturan perundang-undangan yang
                berlaku di Indonesia.</li>
            </ol>
            <br></br>
            <p className="text-sm mt-6 text-justify">
              Ketika terdapat Data Pribadi yang tidak perlu dikaitkan dengan Pekerja, Perusahaan akan melakukan
              upaya yang wajar untuk menghapus kaitan Data Pribadi tersebut dengan Pekerja sebagai individu
              sebelum mengungkapkan atau berbagi Data Pribadi tersebut.
            </p>
            <br></br>
            <ol style={{ listStyleType: "upper-alpha" }} className="ml-6 text-sm text-justify" start="7">
              <li>Perusahaan memproses Data Pribadi Pekerja paling singkat 5 (lima) tahun dan paling lama
                selama Perusahaan masih melaksanakan tujuan-tujuan pemrosesan Data Pribadi oleh
                Perusahaan sebagaimana dijelaskan di atas.</li><br></br>
              <li>Pekerja memiliki berbagai hak terkait Data Pribadi tentang dirinya sesuai dengan ketentuan
                Peraturan Perundang-undangan yang Berlaku, termasuk berhak untuk (i) meminta perbaikan
                Data Pribadi apabila terdapat ketidakakuratan dalam Data Pribadi dan (ii) meminta akses,
                koreksi dan/atau penghapusan terhadap Data Pribadi yang berada dalam penguasaan
                dan/atau kendali Perusahaan selama diperbolehkan atau diperlukan oleh Peraturan
                Perundang-undangan yang berlaku.
              </li><br></br>
              <li>Pekerja turut berkewajiban untuk melindungi Data Pribadi seluruh Pekerja dan Pihak Ketiga
                yang diproses oleh Perusahaan dalam hal kerahasiaan, integritas, dan ketersediaan Data
                Pribadi, termasuk pelanggaran keamanan, baik yang disengaja maupun yang tidak disengaja,
                yang mengarah pada perusakan, kehilangan, perubahan, pengungkapan, atau akses yang
                tidak sah terhadap Data Pribadi yang dikirim, disimpan, atau diproses dengan menaati
                prinsip pelindungan Data Pribadi dan Peraturan Perundang-undangan yang berlaku.</li><br></br>
              <li>Perusahaan tidak bertanggung jawab atas setiap kerugian yang timbul akibat kerusakan atau
                kehilangan Data Pribadi Pekerja yang terjadi di luar kendali dan/atau kewajiban Perusahaan
                berdasarkan Peraturan Perundang-undangan yang berlaku.</li>
            </ol>
            
            {/* Section III */}
            <h2 className="text-sm font-bold mt-10">1. III. KETENTUAN LAIN-LAIN</h2>
            <br></br>
            <ol style={{ listStyleType: "upper-alpha" }} className="ml-6 text-sm">
              <li>JANGKA WAKTU KEBIJAKAN</li>
            </ol>
            <br></br>
            <ol className="list-decimal text-sm ml-16 pl-8 text-justify">
              <li>
                KEBIJAKAN ini akan terus berlaku kecuali diakhiri oleh Perusahaan dan Pekerja</li><br></br>
              <li>
                Pengakhiran KEBIJAKAN ini dan/atau berakhirnya status hubungan kerja Pekerja
                dengan Perusahaan tidak akan mempengaruhi hak dan tanggung jawab suatu pihak
                pada tanggal pengakhiran dan kewajiban Para Pihak berdasarkan KEBIJAKAN ini
                dimaksudkan untuk tetap berlaku walaupun KEBIJAKAN ini diputuskan.
              </li><br></br>
              <li>
                Para Pihak dengan ini mengesampingkan Pasal 1266 Kitab Undang-Undang Hukum
                Perdata sejauh mana diperlukan untuk melaksanakan pemutusan KEBIJAKAN ini
                tanpa melalui putusan pengadilan.
              </li><br></br>
            </ol>
            <br></br>
            <ol style={{ listStyleType: "upper-alpha" }} className="ml-6 text-sm" start="2">
              <li>HUKUM YANG BERLAKU DAN PENYELESAIAN SENGKETA</li>
            </ol>
            <br></br>
            <ol className="list-decimal text-sm ml-16 pl-8 text-justify">
              <li>
                KEBIJAKAN ini tunduk pada dan harus ditafsirkan sesuai dengan hukum negara
                Republik Indonesia.</li><br></br>
              <li>
                Perusahaan memiliki hak untuk melakukan segala upaya hukum yang diperlukan,
                baik sesuai Kitab Undang-Undang Hukum Pidana maupun Kitab Undang-Undang
                Hukum Perdata sehubungan dengan setiap pelanggaran, kelalaian dan/atau
                penyalahgunaan Informasi Rahasia berdasarkan Kebijakan ini.</li><br></br>
              <li>
                Perusahaan dan Pekerja sepakat bahwa dalam hal terjadi perbedaan, sengketa,
                perselisihan atau kontroversi (“<strong>Perselisihan</strong>”) sehubungan dengan atau dalam
                kaitannya dengan KEBIJAKAN ini atau pelaksanaannya, termasuk perselisihan
                mengenai keberadaan, keberlakuan dan berakhirnya hak-hak dan kewajibankewajiban salah satu pihak, Perusahaan dan Pekerja akan mencoba menyelesaikan
                Perselisihan tersebut secara musyawarah selama 30 (tiga puluh) hari setelah
                diterimanya pemberitahuan oleh satu pihak dari pihak yang lainnya mengenai
                Perselisihan tersebut. Apabila Perusahaan dan Pekerja tidak dapat mencapai
                kesepakatan untuk menyelesaikan Perselisihan dalam waktu 30 (tiga puluh) hari
                sebagaimana dimaksud dalam Pasal ini, maka Perusahaan dan Pekerja sepakat
                untuk menyelesaikan Perselisihan tersebut di Pengadilan Negeri Bekasi.
              </li>
            </ol>
            <p className="text-sm mt-6 pl-4">
              C. KETERPISAHAN</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Apabila pengadilan yang berwenang menerbitkan putusan yang isinya menyatakan bahwa
              suatu ketentuan dalam KEBIJAKAN ini tidak valid atau tidak dapat dilaksanakan, maka
              ketentuan lain dari KEBIJAKAN ini tetap berlaku secara penuh. Ketentuan lain dalam
              KEBIJAKAN ini yang secara sebagian dinyatakan tidak berlaku atau tidak dapat dilaksanakan
              akan tetap berlaku penuh sejauh mana ketentuan tersebut tidak dinyatakan tidak berlaku
              atau tidak dapat dinikmati.</p>

            <p className="text-sm mt-6 pl-4">
              D. KESELURUHAN KEBIJAKAN</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              KEBIJAKAN ini (bersama dengan dokumen lainnya yang dirujuk di dalamnya) merupakan
              satusatunya kebijakan yang disepakati oleh Perusahaan dan Pekerja serta merupakan
              pernyataan yang lengkap dan akurat dari ketentuan-ketentuan dalam KEBIJAKAN ini antara
              Perusahaan dan Pekerja sehubungan dengan hal diatas.</p>

            <p className="text-sm mt-6 pl-4">
              E. PERUBAHAN KEBIJAKAN</p>
            <p className="text-sm mt-4 pl-8 text-justify">
              Dari waktu ke waktu, Perusahaan dapat merevisi KEBIJAKAN ini untuk mencerminkan
              perubahan dalam aturan hukum, pengumpulan dan praktik penggunaan Informasi Rahasia
              dan Data Pribadi, fitur aplikasi, atau kemajuan dalam teknologi. Jika Perusahaan membuat
              revisi yang mengubah cara kami mengumpulkan atau menggunakan Informasi Rahasia dan
              Data Pribadi Pekerja, perubahan tersebut akan dimuat di dalam KEBIJAKAN ini.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
