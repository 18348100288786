import React, { useEffect } from "react";
import { background16, pakamalik, pakarie, pakhar } from "../../../assets";
import BackgroundOverlay from "../../../components/Fragments/BackgroundOverlay";

const Boards = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = "Boards Of Director";
  return (
    <>
      <main className="h-full w-full">
        <BackgroundOverlay content={title} gambar={background16} />

        <div className="-mt-20 pb-20 bg-slate-50 overflow-x-hidden">
          <div className="pb-20 lg:pb-24 pt-24 lg:pt-24 bg-gradient-to-b from-slate-50 to-slate-100">
            <div className="container m-auto lg:px-20 text-gray-600 md:px-12">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden mx-5">
                  <img className="w-full h-auto object-cover" alt="bod" src={pakarie} />
                  <div className="bg-gray-200 text-center p-4">
                    <h3 className="font-bold text-xl md:text-lg py-2 uppercase tracking-wide">
                      Arie Sasongko
                    </h3>
                    <p className="text-base font-medium md:text-sm lg:text-base">
                      President Director
                    </p>
                  </div>
                </div>

                <div className="bg-white shadow-lg rounded-lg overflow-hidden mx-5">
                  <img className="w-full h-auto object-cover" alt="bod" src={pakamalik} />
                  <div className="bg-gray-200 text-center p-4">
                    <h3 className="font-bold text-xl md:text-lg py-2 uppercase tracking-wide">
                      Amalik
                    </h3>
                    <p className="text-base font-medium md:text-sm lg:text-base">
                      Director
                    </p>
                  </div>
                </div>

                <div className="bg-white shadow-lg rounded-lg overflow-hidden mx-5">
                  <img className="w-full h-auto object-cover" alt="bod" src={pakhar} />
                  <div className="bg-gray-200 text-center p-4">
                    <h3 className="font-bold text-xl md:text-lg py-2 uppercase tracking-wide">
                      YFX Haryono Sulistio
                    </h3>
                    <p className="text-base font-medium md:text-sm lg:text-base">
                      Director
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </main>
    </>
  );
};
export default Boards;
