import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsent.css';

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(['userConsent']);
    const [showPopup, setShowPopup] = useState(!cookies.userConsent);
  
    const handleAcceptCookies = () => {
      setCookie('userConsent', true, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
      setShowPopup(false);
    };
  
    return (
      <>
        {showPopup && (
          <div className="cookie-popup">
            <p>Klik <strong>“Setuju”</strong> apabila Anda setuju untuk menggunakan cookies pada situs web kami. Kami menggunakan cookies untuk meningkatkan pengalaman Anda pada situs web kami dan untuk memahami Pemberitahuan Privasi kami terhadap cookies yang kami gunakan, silahkan mengakses pada halaman <a href="/privacy-policy" className="cookie-link">Pemberitahuan Privasi</a> kami.{' '}
              
            </p>
            <button className="cookie-button" onClick={handleAcceptCookies}>Setuju</button>
          </div>
        )}
      </>
    );
  };
  

export default CookieConsent;
