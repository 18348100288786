// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookie-popup {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff; /* Warna latar belakang */
    color: white;
    padding: 20px;
    text-align: left;
    z-index: 1000;
    border-top: 3px solid #D32F2F; /* Garis di atas */
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    
  }
  
  .cookie-popup p {
    font-size: 12px;
    margin: 0;
    color: #000000;
    flex: 1 1; 
  }
  
  .cookie-link {
    color: #608DFD;

  }
  
  .cookie-link:hover {
    text-decoration: underline;
  }
  
  .cookie-button {
    background-color: #D32F2F; 
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .cookie-button:hover {
    background-color: #C62828; /* Warna tombol saat di-hover */
  }
  
  .cookie-button:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(255, 215, 0, 0.5); /* Efek saat tombol fokus */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CookieConsent/CookieConsent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,sBAAsB,EAAE,yBAAyB;IACjD,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,6BAA6B,EAAE,kBAAkB;IACjD,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;EAErB;;EAEA;IACE,eAAe;IACf,SAAS;IACT,cAAc;IACd,SAAO;EACT;;EAEA;IACE,cAAc;;EAEhB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB,EAAE,+BAA+B;EAC5D;;EAEA;IACE,aAAa;IACb,8CAA8C,EAAE,2BAA2B;EAC7E","sourcesContent":[".cookie-popup {\r\n    position: fixed;\r\n    bottom: 0;\r\n    width: 100%;\r\n    background-color: #fff; /* Warna latar belakang */\r\n    color: white;\r\n    padding: 20px;\r\n    text-align: left;\r\n    z-index: 1000;\r\n    border-top: 3px solid #D32F2F; /* Garis di atas */\r\n    display: flex; \r\n    justify-content: space-between; \r\n    align-items: center;\r\n    \r\n  }\r\n  \r\n  .cookie-popup p {\r\n    font-size: 12px;\r\n    margin: 0;\r\n    color: #000000;\r\n    flex: 1; \r\n  }\r\n  \r\n  .cookie-link {\r\n    color: #608DFD;\r\n\r\n  }\r\n  \r\n  .cookie-link:hover {\r\n    text-decoration: underline;\r\n  }\r\n  \r\n  .cookie-button {\r\n    background-color: #D32F2F; \r\n    color: white;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    margin-left: 15px;\r\n    cursor: pointer;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .cookie-button:hover {\r\n    background-color: #C62828; /* Warna tombol saat di-hover */\r\n  }\r\n  \r\n  .cookie-button:focus {\r\n    outline: none;\r\n    box-shadow: 0 0 5px 2px rgba(255, 215, 0, 0.5); /* Efek saat tombol fokus */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
